<template>
  <pop-up-firma
    :form="tattooer"
    name="signature"
    class="mt-1"
    :cancel_not_delete="true"
    :text="$t('signature')"
    :never_outlined="true"
    @save="saveSetting"
  ></pop-up-firma>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Signature Artist",
  data() {
    return {
      tattooer: {},
      key: "artist_signature",
    };
  },
  components: {
    PopUpFirma: () => import("@/components/ui/PopUpFirma"),
  },
  mounted() {
    this.fetchSetting();
  },
  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    fetchSetting() {
      this.getSetting({ key: this.key }).then((x) => {
        this.tattooer.signature = x.data.value;
      });
    },
    saveSetting() {
      this.settingUpdate({
        key: this.key,
        value: this.tattooer.signature,
      }).then((data) => {
        this.fetchSetting();
        this.$alert(this.$t("save_ok"));
      });
    },
  },
};
</script>
